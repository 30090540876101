


















































































































































































































































































































































































.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-item {
    width: 45%;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.framePage-scroll /deep/ .upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
