











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// 截图
.cropper-content {
  .cropper {
    width: auto;
    height: 578px;
  }
}
.cropper_tip {
  background-color: #f1f7fd;
  padding: 15px 20px;
  h3 {
    margin-bottom: 15px;
  }
  .tip_index {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #5b6be7;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }
  .tip_text {
    min-width: 140px;
  }
}
//
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
.pic_upload {
  .el-upload {
    border: 0;
    display: block;
    max-height: 50px !important;
    text-align: left;
  }
}
.idPhoto {
  div {
    position: relative;
    display: flex;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
// 图片裁剪
.vueCropper_dialog {
  .el-upload {
    display: inline-block;
    border: 0;
    height: 16px !important;
  }
}
